<template>
  <div style="width: 100%">
    <div class="container">
      <page-header-image />
      <div style="margin-top: 100px"></div>
      <div class="contentContainer">
        <div class="slideBar">
          <div class="brief" :class="{ active: brief }" @click="showBrief">
            招聘简章
            <img
              class="vector"
              src="..\static\img\Vector.png"
              alt=""
              :class="{ activeVector: brief }"
              @click="showBrief"
            />
          </div>
          <div class="job" :class="{ active: job }" @click="showJob">
            招聘岗位
            <img
              class="vector"
              src="..\static\img\Vector.png"
              alt=""
              :class="{ activeVector: job }"
              @click="showJob"
            />
          </div>
          <div class="address" :class="{ active: address }" @click="showAddress">
            联系地址
            <img
              class="vector"
              src="..\static\img\Vector.png"
              alt=""
              :class="{ activeVector: address }"
              @click="showAddress"
            />
          </div>
          <div class="lives" :class="{ active: lives }" @click="showLives">
            员工生活
            <img
              class="vector"
              src="..\static\img\Vector.png"
              alt=""
              :class="{ activeVector: lives }"
              @click="showLives"
            />
          </div>
        </div>
        <div class="content">
          <div class="briefContainer" :class="{ activeContent: brief }" @click="showBrief">
            <h2>招聘简章</h2>
            <p style="text-indent: 2em">
              宝德仕电玩制造（深圳）有限公司是1985年成立于深圳坪山区﹐2012年改制为外资投资企业。是一家从事设计、制造和销售人体健康按摩器、IA人工智能国际象棋、
              电子音乐类小孩启蒙和教育益智等高科技产品为主的大型港资企业﹐
              所有产品全部销往欧美及世界其它发达国家和地区﹐是香港著名的电子消费品制造公司之一。
            </p>
            <p style="text-indent: 2em">
              公司厂房面积8315多平方米﹐职工200多人，其中管理和技术型人才有60多人；拥有从产品开发设计﹑模具制作﹑自动注塑﹑硅胶成型及SMT﹑电路板加工﹑成品装配等一条龙的生产强大能力。
              主要产品有人体健康按摩器﹑人工智能国际象棋、电子乐器﹑教育益智类等数十种不同形式的电子高科技产品。
              近年来公司全力开发和生产的高端电子产品，例如：国际象棋、按摩器等受到市场的好评。<br />
              公司采用国际质量管理体系并获得ISO9001:
              2015认证，为客户提供及时满意的产品和服务。公司诚意邀请您的加盟，一起创造属于自己的未来。
            </p>
          </div>
          <div class="jobContainer" :class="{ activeContent: job }" @click="showJob">
            <h2>招聘岗位</h2>
            <!-- 
            <p>
              本公司所有员工实行职等职级管理，每个岗位都有绩效考核指标（KPI）奖金。诚邀对于有志从事电子产品研发和生产、
              制造的人仕加盟公司，且愿意从底层做起，与公司共同发展的您，就是公司期望的人才。因新增产能，现诚聘：
            </p>
            <p>1.长期招聘男女普工和中专以上储干10名；</p>
            <p>
              2.电子、结构工程师，IE工程师、QE工程师多名，大专以上，有多年操作制图软件，熟练3D曲面结构给图，懂开模经验更佳。
            </p>
            <br />
            <p>
              公司实行双休，加班控制在合理范围。为所有员工提供公寓式的住宿环境（含独立卫生间、空调和热水器等）；
              普工的工资按照劳动法支付，底薪2360元/月起，月均收入约4500元左右；
              平常加班1.5倍，周末2倍，节假日3倍，技术职工享有更多职等职级的补助和津贴。公司根据个人KPI现表和能力让每个员工均有公平的晋升机会；结构工程师的薪酬水平在8-10K之间。
            </p>
            <br />
            <p>
              福利：不用公司安排住宿的，每月给予120元以上的补助。工作一年以上即可享受5天有薪年假。
            </p>
            <br />
            <p>录用条件：年满18周岁；身体健康，无不良嗜好。</p> -->
            <p v-for="item in jobText" :key="item">{{ item }}<br /></p>
          </div>
          <div class="addressContainer" :class="{ activeContent: address }" @click="showAddress">
            <h2>联系地址</h2>
            <p>
              深圳市坪山区龙田街道竹坑社区金牛东路第三工业区C区7号楼
              <br /><br />
              办公室：(0755) 6186 2318 陈小姐
              <br />
              制造工厂：13537783630 梁小姐
              <br /><br />
              公司QQ：2664366058 <br /><br />
              邮箱：hr@bdstechml.com
            </p>
          </div>
          <div class="livesContainer" :class="{ activeContent: lives }" @click="showLives">
            <div class="livesItem">
              <img src="..\static\img\live7.jpg" alt="" />
              <p>员工宿舍环境<br />Employee dormitory environment</p>
            </div>
            <div class="livesItem">
              <img src="..\static\img\live6.jpg" alt="" />
              <p>员工宿舍环境<br />Employee dormitory environment</p>
            </div>
            <div class="livesItem">
              <img src="..\static\img\live1.png" alt="" />
              <p>
                人脸识别进入车间<br />
                Face recognition into the workshop
              </p>
            </div>
            <div class="livesItem">
              <img src="..\static\img\live4.png" alt="" />
              <p>
                员工文化宣传栏<br />
                Cultural promotion column
              </p>
            </div>
            <div class="livesItem">
              <img src="..\static\img\live2.png" alt="" />
              <p>
                入口欢迎大厅<br />
                Entrance Welcome Hall
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 150px"></div>
      <!-- <float-box /> -->
      <!-- <index-footer /> -->
    </div>
  </div>
</template>
<script>
import FloatBox from "../components/floatBox.vue";
import IndexFooter from "../components/IndexFooter.vue";
import PageHeaderImage from "../components/PageHeaderImage.vue";

export default {
  components: { PageHeaderImage, IndexFooter, FloatBox },
  data() {
    return {
      brief: true,
      job: false,
      address: false,
      lives: false,
      jobText: [],
    };
  },
  methods: {
    showBrief() {
      this.brief = true;
      this.job = false;
      this.address = false;
      this.lives = false;
    },
    showJob() {
      this.brief = false;
      this.job = true;
      this.address = false;
      this.lives = false;
    },
    showAddress() {
      this.brief = false;
      this.job = false;
      this.address = true;
      this.lives = false;
    },
    showLives() {
      this.brief = false;
      this.job = false;
      this.address = false;
      this.lives = true;
    },
  },
  created() {
    this.$axios({
      method: "GET",
      url: "/website/getJobSeekingTxt",
    }).then((res) => (this.jobText = res.data.data.content.split("\n")));
  },
};
</script>

<style scoped>
* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 70px;
  position: relative;
  left: 5%;
  width: 90%;
}

.slideBar {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 476.25px;
  width: 100%;
}

.brief,
.job,
.address,
.lives {
  position: relative;
  width: 100%;
  height: 80px;
  background: #1f89eb;
  margin-top: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-size: 32px;
  line-height: 80px;
  color: #ffffff;
  padding-left: 30px;
  text-align: start;
  transition: all 0.5s;
  cursor: pointer;
}

.active {
  background: #041d72;
  transition: all 0.5s;
  height: 206.25px;
}

.vector {
  position: absolute;
  right: 30px;
  top: 24px;
  height: 32px;
  transition: all 0.5s;
}

.activeVector {
  transform: rotate(90deg);
}

.content {
  position: relative;
  width: 100%;
  transition: all 0.5s;
}

h2 {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 75px;
}

.briefContainer,
.jobContainer,
.addressContainer,
.livesContainer {
  width: inherit;
  display: none;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #0e0e0e;
  opacity: 0;
  transition: all 0.5s;
  margin-top: 10px;
}

.jobContainer p {
  text-indent: 2em;
  white-space: pre-line;
}

.activeContent {
  opacity: 1;
  transition: all 0.5s;
  display: inline-block;
}

.livesItem {
  width: 100%;
  margin-bottom: 90px;
}

.livesItem > img {
  width: 100%;
  height: 250px;
}

.livesItem > p {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #0e0e0e;
  text-align: center;
}

@media screen and (max-width: 1050px) {
  .contentContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  .slideBar {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 80px;
    width: 100%;
    margin-bottom: 30px;
  }

  .brief,
  .job,
  .address,
  .lives {
    margin-top: 0;
    margin-right: 10px;
    padding: 0;
    text-align: center;
  }

  .vector {
    display: none;
  }

  .active {
    background: #041d72;
    transition: all 0.5s;
    height: 80px;
  }
}

@media screen and (max-width: 750px) {
  .brief,
  .job,
  .address,
  .lives {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }

  .slideBar {
    height: 60px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .livesItem > img {
    width: 100%;
    height: 400px;
    z-index: -1;
  }
}
</style>
